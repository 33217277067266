import { RxCross2 } from 'react-icons/rx'
import styled from 'styled-components'

import { useEscKeyPress } from '@/hooks/useEscKeyPress.tsx'

export const StyledModal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 24px;
  gap: ${(props) => props.theme.spacing}px;
  border-radius: 8px;
`
export const ModalBackgroundStyling = styled.div`
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(2, 2, 2, 0.4);
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
`
export const ModalActionButtons = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing * 2}px;
  padding-top: 24px;
  width: 100%;
  > button {
    flex: 1;
  }
`

const CloseModalIcon = styled.button`
  border: 1px solid transparent;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.modal + 1};

  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.colors.common.accentDark};
  }
`

export function CloseModalButton({
  className,
  onClick,
}: {
  className?: string
  onClick: () => void
}) {
  return (
    <CloseModalIcon className={className} onClick={onClick}>
      <RxCross2 />
    </CloseModalIcon>
  )
}

interface ModalBackgroundProps {
  closeModal: () => void
}

export function ModalBackground({
  children,
  closeModal,
}: React.PropsWithChildren<ModalBackgroundProps>) {
  const closeModalOnBackgroundClick = (
    event: React.UIEvent<HTMLDivElement>,
  ) => {
    const modal = document.getElementById('modalBG')
    if (event.target === modal) {
      closeModal()
    }
  }
  useEscKeyPress(closeModal)

  return (
    <ModalBackgroundStyling id="modalBG" onClick={closeModalOnBackgroundClick}>
      {children}
    </ModalBackgroundStyling>
  )
}
