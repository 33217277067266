import { Organization } from '@/hooks/admin/useOrganizations.ts'
import { Reason, ReasonWithOrganizations } from '@/types/reason.ts'

export const normalizeReasons = (
  reasons?: Reason[],
  organizations?: Organization[],
): ReasonWithOrganizations[] =>
  reasons?.map((reason) => ({
    ...reason,
    organizations:
      organizations?.filter(
        (org) =>
          reason.organizationIds?.includes(org.ids[0]) &&
          (!org.medicalCategory ||
            reason.medicalCategories?.includes(org.medicalCategory)),
      ) || [],
  })) || []

export const denormalizeReason = (
  reason: ReasonWithOrganizations,
): Reason => ({
  ...reason,
  medicalCategories: reason.organizations.reduce(
    (acc, org) => (org.medicalCategory ? [...acc, org.medicalCategory] : acc),
    [] as string[],
  ),
  organizationIds: reason.organizations.map((org) => org.ids).flat(),
})
