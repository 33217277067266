import { Menu } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { RxGear } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { Permissions } from '~common/auth/permissions.ts'

import * as S from './AdminButton.styled.ts'

export const AdminButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'navbar',
  })
  const navigate = useNavigate()
  const clinician = useCurrentUser()

  const showAdminPanel = clinician.permissions.includes(Permissions.AdminPanel)
  const showAdminDashboard = clinician.permissions.includes(
    Permissions.AdminDashboard,
  )

  if (!showAdminPanel && !showAdminDashboard) {
    return <></>
  }

  return (
    <S.Wrapper>
      <Menu>
        <StyledActionButton as={Menu.Button} size="small" variant="Primary">
          {t('adminPanel')}
          <RxGear />
        </StyledActionButton>
        <S.MenuItems>
          <S.AdminMenu direction="column" spacing={4}>
            {showAdminDashboard && (
              <Menu.Item>
                <S.NavButton onClick={() => navigate('/dashboard')}>
                  {t('dashboard')}
                </S.NavButton>
              </Menu.Item>
            )}
            {showAdminPanel && (
              <Menu.Item>
                <S.NavButton onClick={() => navigate('/admin')}>
                  {t('userManagements')}
                </S.NavButton>
              </Menu.Item>
            )}
          </S.AdminMenu>
        </S.MenuItems>
      </Menu>
    </S.Wrapper>
  )
}
