import type { Identifier, XYCoord } from 'dnd-core'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RxCross2 } from 'react-icons/rx'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Input, Label, TextArea } from '@/components/common/Input.tsx'
import { Tile } from '@/components/common/Tile.tsx'
import { FrequencyListbox } from '@/components/templates/Tiles/FrequencyListbox'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext'
import { Exercise } from '@/types/exercise.ts'

const EXERCISES_FREQUENCY_OPTIONS = ['day', 'hour']

const FlexContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
  justify-content: space-between;
`

const Draggable = styled.div<{ isDragging: boolean }>`
  position: relative;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};

  &:hover {
    box-shadow: ${({ theme }) =>
      `4px 4px 20px 0px ${theme.colors.common.black20}`};
  }
`

const DraggedPlaceholder = styled.div`
  background: ${({ theme }) => theme.colors.common.black05};
  height: 100%;
`

const VideoPreviewButton = styled(StyledActionButton)`
  position: absolute;
  left: 22px;
  top: 22px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.36px;
  font-weight: 500;
  text-transform: uppercase;
`

type ExerciseTileProps = {
  exercise: Exercise
  index: number
  onVideoPreviewClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void,
  saveDragAndDropOrder: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  id: string
  index: number
  type: string
}

export const ExerciseTile = ({
  exercise,
  index,
  onVideoPreviewClick,
  saveDragAndDropOrder,
}: ExerciseTileProps) => {
  const { actions } = useHandTherapyContext()
  const { register } = useFormContext()
  const keyPrefix = 'handTherapy.exercises'
  const { t } = useTranslation('translation', {
    keyPrefix,
  })

  const handleRemoveExercise = () => {
    actions?.removeExercise(exercise.contentfulId)
    actions?.setIsDirty(true)
  }

  const ref = useRef<HTMLDivElement>(null)
  const [_, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'exercise',
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine tile dimensions on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get horizontal middle for tile
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 4

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the right
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left

      // Dragging right but not far enough
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }

      // Dragging left but not far enough
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      // Dragged far enough, save to state
      saveDragAndDropOrder(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => ({ id: exercise.id, index }),
    type: 'exercise',
  })

  drag(drop(ref))

  return (
    <div ref={ref}>
      {isDragging && <DraggedPlaceholder />}
      <Draggable isDragging={isDragging}>
        <Tile
          imageSrc={exercise.thumbnail}
          onClose={handleRemoveExercise}
          title={exercise.name}
        >
          <FlexContainer>
            <Label>
              {t('frequency')}
              <Input
                min={1}
                type="number"
                {...register(`frequency_${exercise.contentfulId}`)}
              />
            </Label>
            <RxCross2 height={36} style={{ marginBottom: 8 }} width={18} />
            <FrequencyListbox
              defaultValue={exercise.details.frequencyTime}
              i18nKeyPrefix={keyPrefix}
              name={`frequencyTime_${exercise.contentfulId}`}
              options={EXERCISES_FREQUENCY_OPTIONS}
            />
            <Label>
              {t('sets')}
              <Input
                min={1}
                type="number"
                {...register(`sets_${exercise.contentfulId}`)}
              />
            </Label>
          </FlexContainer>
          <FlexContainer>
            <Label>
              {t('reps')}
              <Input
                min={1}
                type="number"
                {...register(`repetitions_${exercise.contentfulId}`)}
              />
            </Label>
            <Label>
              {t('rest')}
              <Input {...register(`rest_${exercise.contentfulId}`)} />
            </Label>
          </FlexContainer>
          <Label>
            {t('instructions')}
            <TextArea
              {...register(`instructions_${exercise.contentfulId}`)}
              rows={4}
            />
          </Label>
        </Tile>
        {exercise.video && onVideoPreviewClick && (
          <VideoPreviewButton
            onClick={onVideoPreviewClick}
            size="small"
            variant="Secondary"
          >
            {t('videoPreview')}
          </VideoPreviewButton>
        )}
      </Draggable>
    </div>
  )
}
