import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: flex;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  width: 14px;
  height: 16px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.common.accentDark : theme.colors.common.white};
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.colors.common.black75}`};
  transition: all 150ms;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

const Label = styled.label`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const Checkbox = ({
  checked,
  disabled,
  label,
  onChange,
}: {
  checked: boolean
  disabled?: boolean
  label?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => (
  <Label>
    <CheckboxContainer>
      <HiddenCheckbox
        aria-checked={checked}
        aria-label="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        role="checkbox"
      />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    <span>{label}</span>
  </Label>
)
