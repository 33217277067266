import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { ExercisesSidebar } from '@/components/templates/ExercisesSidebar/ExercisesSidebar.tsx'
import { BaseGridContainer } from '@/components/templates/GridContainer.tsx'
import { SIDEBAR_WIDTH } from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'
import { PatientSidebar } from '@/components/templates/PatientSidebar/Sidebar.tsx'
import {
  HandTherapyProvider,
  SidebarType,
} from '@/contexts/HandTherapyContext.tsx'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'

const GridContainer = styled(BaseGridContainer)`
  grid-template-columns: 1fr fit-content(${SIDEBAR_WIDTH}px);
  grid-template-rows: 1fr;
`

export const HandTherapy = () => {
  return (
    <HandTherapyProvider>
      <Content />
    </HandTherapyProvider>
  )
}

const Content = () => {
  const { sidebar } = useHandTherapyContext()
  const { patientId } = usePatientPulseIdFromParams()

  return (
    <GridContainer>
      <Outlet />
      {sidebar === SidebarType.PatientInfo ? (
        <PatientSidebar pulseIds={patientId} />
      ) : (
        <ExercisesSidebar type={sidebar} />
      )}
    </GridContainer>
  )
}
