import React from 'react'
import { RxCross2 } from 'react-icons/rx'
import styled from 'styled-components'

import { BodyS } from '@/components/common/Text.tsx'

const TileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.common.white};
`

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 11px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background-color: ${({ theme }) => theme.colors.common.white};
  border-radius: 50%;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  margin-bottom: 16px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.common.black05};
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

const Title = styled(BodyS)`
  height: 56px;
  padding-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.common.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`

type TileProps = {
  children: React.ReactNode
  imageSrc: string
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  title: string
}

export const Tile = ({ children, imageSrc, onClose, title }: TileProps) => {
  return (
    <TileContainer>
      <CloseButton onClick={onClose}>
        <RxCross2 height={24} width={24} />
      </CloseButton>
      <ImageContainer>
        <img alt="splint-image" src={imageSrc} />
      </ImageContainer>
      <Title fontWeight="bold">{title}</Title>
      <Fields>{children}</Fields>
    </TileContainer>
  )
}
