import dayjs from 'dayjs'

import { StyledActionButton } from '@/components/common/Buttons.tsx'

type DownloadCSVButtonProps = {
  data: any[]
  disabled?: boolean
  fileName: string
  label: string
}

const SEPARATOR = ';'

export const DownloadCSVButton = ({
  data,
  disabled = false,
  fileName,
  label,
}: DownloadCSVButtonProps) => {
  const convertToCSV = (dataArray: any[]) => {
    let str = ''

    // attach headers
    Object.keys(dataArray[0]).forEach((key, index) => {
      if (index !== 0) {
        str += SEPARATOR
      }
      str += key
    })
    str += '\r\n'

    for (let i = 0; i < dataArray.length; i++) {
      let line = ''
      for (const index in dataArray[i]) {
        if (line !== '') line += SEPARATOR
        let value = dataArray[i][index]
        if (typeof value === 'string') {
          value = '"' + dataArray[i][index] + '"'
        }
        line += value ?? ''
      }
      str += line + '\r\n'
    }
    return str
  }

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' })
    const csvURL = URL.createObjectURL(csvData)
    const link = document.createElement('a')
    link.href = csvURL
    link.download = `${fileName}_${dayjs().format('DD_MM_YYYY')}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <StyledActionButton
      disabled={disabled}
      onClick={downloadCSV}
      variant="Secondary"
    >
      {label}
    </StyledActionButton>
  )
}
