import { Disclosure } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import styled from 'styled-components'

import { getTagsData, Tags as TagsComponent } from '@/components/admin/Tags.tsx'
import { Input } from '@/components/common/Input.tsx'

const Tags = styled(TagsComponent)`
  margin-top: 6px;
`
const DisclosureButton = styled(Disclosure.Button)`
  position: relative;
  width: 100%;
`

const ChevronIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  position: absolute;
  top: 8px;
  right: 0;
`

const ITEMS_TO_SHOW = 5

type TagInputProps = {
  handleInputChange: (id: string, value: string[]) => void,
  id: string,
  prevTags?: string[]
}

export const TagInput = ({
  handleInputChange,
  id,
  prevTags,
}: TagInputProps) => {
  const [tags, setTags] = useState<string[]>(prevTags || [])
  const parsedTags = tags.map((tag) => ({ id: tag, name: tag }))
  const [tagColorMap, setTagColorMap] = useState<{
    [key: string]: { backgroundColor: string; color: string; name: string }
  }>({})
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    setTagColorMap((prevValue) => getTagsData(parsedTags, prevValue))
  }, [tags])

  const handleAddTag = () => {
    if (inputValue) {
      const parsedInputValues = inputValue.split('"').join().split(',').filter(tag => tag.trim() !== '')

      const updatedTags = [...tags, ...parsedInputValues]
      setTags(updatedTags)
      setInputValue('')
      handleInputChange(id, updatedTags)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddTag()
    }
  }

  const handleDeleteTag = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tagId: string | number) => {
    e.stopPropagation()
    const updatedTags = tags.filter((tag) => tag !== tagId)
    setTags(updatedTags)
    handleInputChange(id, updatedTags)
  }

  return (
    <React.Fragment>
      <Input
        key={id}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        type="text"
        value={inputValue}
      />
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <React.Fragment>
            <DisclosureButton>
              <Tags
                colorMap={tagColorMap}
                data={parsedTags.slice(0, ITEMS_TO_SHOW)}
                onDelete={handleDeleteTag}
              />
              {parsedTags.length > ITEMS_TO_SHOW && (
                <ChevronIcon>
                  {open ? <BsChevronUp /> : <BsChevronDown />}
                </ChevronIcon>
              )}
            </DisclosureButton>
            {parsedTags.length > ITEMS_TO_SHOW && (
              <Disclosure.Panel>
                <Tags
                  colorMap={tagColorMap}
                  data={parsedTags.slice(ITEMS_TO_SHOW)}
                  onDelete={handleDeleteTag}
                />
              </Disclosure.Panel>
            )}
          </React.Fragment>
        )}

      </Disclosure>
    </React.Fragment>
  )
}
