import { useState } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RxMagnifyingGlass } from 'react-icons/rx'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import CloseIcon from '@/assets/svgs/close-line.svg'
import { Dropdown } from '@/components/common/Dropdown.tsx'
import { Search } from '@/components/common/Search.tsx'
import { Header4 } from '@/components/common/Text.tsx'
import { TileSmall } from '@/components/common/TileSmall.tsx'
import { SIDEBAR_WIDTH } from '@/components/templates/PatientSidebar/Sidebar.styled.tsx'
import { SidebarType } from '@/contexts/HandTherapyContext'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext.ts'
import { useExercises } from '@/hooks/data/useExercises.ts'
import { Exercise } from '@/types/exercise.ts'

const SidebarContainer = styled.aside`
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 100%;
  overflow: auto;
  width: ${SIDEBAR_WIDTH}px;
`

const DropdownContainer = styled.div`
  min-width: 200px;
  position: relative;
  z-index: 999;
`

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  gap: 16px;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseButton = styled.button`
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.background};
  }
`

const TileList = ({
  options,
  selectedOptions,
}: {
  options: Exercise[]
  selectedOptions: Exercise[]
}) => {
  const { actions } = useHandTherapyContext()

  const handleOnClick = (exercise: Exercise) => {
    const selected = selectedOptions.some(
      (option) => option.contentfulId === exercise.contentfulId,
    )

    if (selected) {
      actions?.removeExercise(exercise.contentfulId)
      actions?.setIsDirty(true)
      return
    }

    actions?.addExercise(exercise)
    actions?.setIsDirty(true)
  }

  return (
    <TilesContainer>
      {options
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((exercise) => (
          <React.Fragment key={exercise.contentfulId}>
            <TileSmall
              imageSrc={exercise.thumbnail}
              onClick={() => handleOnClick(exercise)}
              selected={selectedOptions.some(
                (option) => option.contentfulId === exercise.contentfulId,
              )}
              title={exercise.name}
            />
          </React.Fragment>
        ))}
    </TilesContainer>
  )
}

const ExerciseTileList = ({
  allExercises,
  exercises,
  selectedExercises,
}: {
  allExercises: Exercise[]
  exercises: Exercise[]
  selectedExercises: Exercise[]
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy.sidebar',
  })

  const sortBySelected = (exerciseA: Exercise) => {
    return selectedExercises.find(
      (selected) => selected.contentfulId === exerciseA.contentfulId,
    )
      ? -1
      : 1
  }

  const filterByAreaOfConcern = (exercise: Exercise) =>
    areaFilter === undefined ||
    areaFilter === 'all' ||
    exercise.areaOfConcern === areaFilter

  const [query, setQuery] = useState<string | null>(null)
  const [areaFilter, setAreaFilter] = useState<string | undefined>()

  const filtered = areaFilter
    ? allExercises.filter(filterByAreaOfConcern)
    : exercises.filter(filterByAreaOfConcern)

  const filteredExercises = !query
    ? filtered
    : allExercises
        .filter((exercise) =>
          exercise.name.toLowerCase().includes(query.toLowerCase()),
        )
        .filter(filterByAreaOfConcern)
        .sort(sortBySelected)

  const getAreasOfConcern = () => {
    // get area of concern filter options from available exercises
    const areasOfConcern = Array.from(
      allExercises.reduce((areas, exercise) => {
        exercise.areaOfConcern && areas.add(exercise.areaOfConcern)
        return areas
      }, new Set<string>()),
    ).map((area) => {
      const titleTranslation = t(
        `area.${area.toLowerCase().replaceAll(' ', '')}`,
        {
          defaultValue: area,
        },
      )

      return {
        id: area,
        title: titleTranslation,
      }
    })

    // add all areas option
    return [{ id: 'all', title: t('allAreasLabel') }, ...areasOfConcern]
  }

  return (
    <>
      <Search>
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('searchExercisesPlaceholder')}
        ></input>
        <RxMagnifyingGlass />
      </Search>
      <DropdownContainer>
        <Dropdown
          items={getAreasOfConcern()}
          onSelect={setAreaFilter}
          prompt={t('areaOfConcernPlaceholder')}
          selectedItemId={areaFilter}
        />
      </DropdownContainer>
      <TileList
        options={filteredExercises}
        selectedOptions={selectedExercises}
      />
    </>
  )
}

export const ExercisesSidebar = ({
  type = SidebarType.Exercises,
}: {
  type?: SidebarType
}) => {
  const { state } = useLocation()
  const { exercises } = useExercises('exercise')
  const { exercises: splints } = useExercises('splint')
  const { actions, currentProgram, defaultPrograms } = useHandTherapyContext()
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy.sidebar',
  })

  const defaultProgram =
    state &&
    defaultPrograms.programs.find(
      (defaultProgram) =>
        defaultProgram.contentfulId === state.programContentfulId,
    )

  if (!currentProgram) {
    return <></>
  }

  const defaultExercises = defaultProgram
    ? defaultProgram.exercises
    : currentProgram.exercises

  const closeSidebar = () => {
    actions?.updateSidebar(SidebarType.PatientInfo)
  }

  return (
    <SidebarContainer>
      <Header>
        <Header4>{t(`title.${type}`)}</Header4>
        <CloseButton onClick={closeSidebar}>
          <img alt="close button" src={CloseIcon} />
        </CloseButton>
      </Header>
      {type === 'exercises' ? (
        <ExerciseTileList
          allExercises={exercises ?? []}
          exercises={defaultExercises}
          selectedExercises={currentProgram.exercises ?? []}
        />
      ) : (
        <TileList
          options={splints ?? []}
          selectedOptions={currentProgram.splints ?? []}
        />
      )}
    </SidebarContainer>
  )
}
