import { Disclosure } from '@headlessui/react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 16px;

  & + & {
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid ${({ theme }) => theme.colors.common.black10};
  }
`
const Button = styled(Disclosure.Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 16px;
`

const Panel = styled(Disclosure.Panel)`
  min-height: 100px;
  overflow: auto;
`

export const Accordion = ({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) => {
  return (
    <Wrapper>
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Button>
              {title} {open ? <BsChevronUp /> : <BsChevronDown />}
            </Button>
            <Panel>{children}</Panel>
          </>
        )}
      </Disclosure>
    </Wrapper>
  )
}
