import 'dayjs/locale/nl'

import { Tab } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { StyledTab } from '@/components/common/Tabs.tsx'
import * as S from '@/screens/admin/UserManagement/UserManagement.styled.ts'

import { ChatDashboard } from './ChatDashboard/ChatDashboard.tsx'
import { UsersDashboard } from './UsersDashboard/UsersDashboard.tsx'

export const Dashboard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard',
  })
  return (
    <S.Container>
      <Tab.Group>
        <Tab.List>
          <StyledTab>{t('tabs.users')}</StyledTab>
          <StyledTab>{t('tabs.chats')}</StyledTab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <UsersDashboard />
          </Tab.Panel>
          <Tab.Panel>
            <ChatDashboard />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </S.Container>
  )
}
