import { Listbox as HeadlessListbox, Transition } from '@headlessui/react'
import { RxCaretDown, RxCaretUp, RxCheck } from 'react-icons/rx'
import styled from 'styled-components'

import { BodyS } from '@/components/common/Text.tsx'

const ListboxWrapper = styled.div`
  position: relative;
`

const ListboxButton = styled(HeadlessListbox.Button)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background-color: ${({ theme }) => theme.colors.common.white};
  color: ${({ theme }) => theme.colors.common.black62};
  border-radius: 2px;
  padding: 6px 12px;
  width: 100%;
  min-width: 85px;
  z-index: 999;
`

const ListboxOptions = styled(HeadlessListbox.Options)`
  position: absolute;
  right: 0;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-top: none;
  background-color: ${({ theme }) => theme.colors.common.white};
  padding: 0 12px;
  border-radius: 0 0 2px 2px;
  z-index: 1;
`

const ListboxOption = styled(HeadlessListbox.Option)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
  color: ${({ theme }) => theme.colors.common.black62};
  padding: 8px 0;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`

const MultiSelectListboxOption = styled(ListboxOption)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors.primary.main};
`

type ListboxProps = {
  className?: string
  onChange: (value?: { title: string; value: string }) => void
  options: { title: string; value: string }[]
  value?: { title: string; value: string }
}

export const Listbox = ({
  className,
  onChange,
  options,
  value,
}: ListboxProps) => {
  return (
    <HeadlessListbox
      as={ListboxWrapper}
      className={className}
      onChange={onChange}
      value={value}
    >
      {({ open }) => (
        <>
          <ListboxButton>
            <BodyS>{value?.title || ''}</BodyS>
            <Icon>{open ? <RxCaretUp /> : <RxCaretDown />}</Icon>
          </ListboxButton>
          <Transition show={open}>
            <ListboxOptions static>
              {options.map((option) => (
                <ListboxOption key={option.value} value={option}>
                  <BodyS>{option.title}</BodyS>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </>
      )}
    </HeadlessListbox>
  )
}

type MuiltiSelectListboxProps = {
  className?: string
  onChange: (value?: string[]) => void
  options: { title: string; value: string }[]
  value?: string[]
}
export const MultiSelectListbox = ({
  className,
  onChange,
  options,
  value,
}: MuiltiSelectListboxProps) => {
  return (
    <HeadlessListbox
      as={ListboxWrapper}
      className={className}
      onChange={onChange}
      value={value}
      multiple
    >
      {({ open }) => (
        <>
          <ListboxButton>
            <BodyS>{value?.map((val) => options.find(option => option.value === val)?.title).filter(Boolean).join(', ') || ''}</BodyS>
            <Icon>{open ? <RxCaretUp /> : <RxCaretDown />}</Icon>
          </ListboxButton>
          <Transition show={open}>
            <ListboxOptions static>
              {options.map((option) => (
                <MultiSelectListboxOption key={option.value} value={option.value}>
                  {({ selected }) => (
                    <>
                      <BodyS>{option.title}</BodyS>
                      {selected && <RxCheck />}
                    </>
                  )}
                </MultiSelectListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </>
      )}
    </HeadlessListbox>
  )
}
