import './i18n'

import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'

import { Providers } from '@/components/templates/Providers.tsx'

import App from './App.tsx'

Sentry.init({
  beforeSend: (event) => {
    if (import.meta.env.ENVIRONMENT !== 'production') return null
    return event
  },
  dsn: import.meta.env.SENTRY_DSN,
  environment: import.meta.env.ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysOnErrorSampleRate: 1.0, // Capture 100% of the errors
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.

  // Tracing
  tracePropagationTargets: ['localhost', /^\//],

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
})

function logErrorToService(error: unknown, info: unknown) {
  // eslint-disable-next-line no-console
  console.error('Caught an error:', error, info)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={<p>Caught an error, check console.</p>}
      onError={logErrorToService}
    >
      <Providers>
        <App />
      </Providers>
    </ErrorBoundary>
  </React.StrictMode>,
)
