import React from 'react'
import { useTranslation } from 'react-i18next'
import { RxMinus, RxPlus } from 'react-icons/rx'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { BodyS } from '@/components/common/Text.tsx'

const ImageContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.common.black05};
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

const Title = styled(BodyS)`
  color: ${({ theme }) => theme.colors.common.black};
`

const TileContainer = styled.div<{ selected: boolean }>`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-radius: 4px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.common.background : theme.colors.common.white};

  ${ImageContainer}, ${Title} {
    opacity: ${({ selected }) => (selected ? 0.62 : 1)};
  }
`

const ActionButton = styled(StyledActionButton)`
  margin-top: auto;
  align-self: flex-end;
`

type TileProps = {
  imageSrc: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  selected?: boolean
  title: string
}

export const TileSmall = ({
  imageSrc,
  onClick,
  selected = false,
  title,
}: TileProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy.actions',
  })
  return (
    <TileContainer selected={selected}>
      <ImageContainer>
        <img alt="exercise-image" src={imageSrc} />
      </ImageContainer>
      <Title>{title}</Title>
      {selected ? (
        <ActionButton onClick={onClick} size="small" variant="Secondary">
          {t('remove')}
          <RxMinus />
        </ActionButton>
      ) : (
        <ActionButton onClick={onClick} size="small" variant="Primary">
          {t('add')}
          <RxPlus />
        </ActionButton>
      )}
    </TileContainer>
  )
}
