import React from 'react'
import { RxPencil1, RxTrash } from 'react-icons/rx'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { StyledActionButtons } from '@/screens/admin/UserManagement/UserManagement.styled.ts'

type ActionButtonsProps = {
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ActionButtons = ({ onDelete, onEdit }: ActionButtonsProps) => {
  return (
    <StyledActionButtons>
      <StyledActionButton onClick={onEdit} size="small" variant="Secondary">
        <RxPencil1 />
      </StyledActionButton>
      <StyledActionButton onClick={onDelete} size="small" variant="Secondary">
        <RxTrash />
      </StyledActionButton>
    </StyledActionButtons>
  )
}
