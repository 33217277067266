import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { User } from '@/types/user.ts'
import { Role } from '~common/auth/role.ts'

import { useFetchFromAPI } from '../data/useFetchFromAPI.ts'

const roleQueryOptions = queryOptions({
  queryKey: ['roles'],
})

export const useRoles = () => {
  const fetchAPI = useFetchFromAPI()

  const { data, isLoading } = useQuery<Role[]>({
    queryFn: async () => fetchAPI(`admin/roles`),
    queryKey: roleQueryOptions.queryKey,
  })

  return { isLoading, roles: data || [] }
}

type CreateRole = { role: Omit<Role, 'id'> }

type useRoleMutationProps = {
  onSuccess?: () => void
}
export const useCreateRole = ({ onSuccess }: useRoleMutationProps) => {
  const fetchAPI = useFetchFromAPI('POST')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    Role,
    unknown,
    CreateRole
  >({
    mutationFn: async ({ role }) => fetchAPI('admin/roles', role),
    onSuccess: (data: Role) => {
      queryClient.setQueryData(roleQueryOptions.queryKey, (oldData?: User[]) => {
        if (oldData) {
          return [...oldData, data]
        }
        return [data]
      })
      onSuccess?.()
    },
  })

  return {
    createRole: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
    newRole: data,
  }
}

type UpdateRole = {
  role: Omit<Role, 'id'>
  roleId: string
}

export const useUpdateRole = ({ onSuccess }: useRoleMutationProps) => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Role,
    unknown,
    UpdateRole
  >({
    mutationFn: async ({ role, roleId }) =>
      fetchAPI(`admin/roles/${roleId}`, role),
    onSuccess: (data: Role) => {
      queryClient.setQueryData(roleQueryOptions.queryKey, (oldData: Role[]) => {
        if (oldData) {
          return oldData.map((role) => {
            if (data.id === role.id) {
              return data
            }
            return role
          })
        }
        return [data]
      })
      onSuccess?.()
    },
  })

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    updateRole: mutate,
  }
}

type DeleteRole = {
  roleId: string
}

export const useDeleteRole = ({ onSuccess }: useRoleMutationProps) => {
  const fetchAPI = useFetchFromAPI('DELETE')
  const queryClient = useQueryClient()

  const { isError, isPending, isSuccess, mutate } = useMutation<
    Role,
    unknown,
    DeleteRole
  >({
    mutationFn: async ({ roleId }) => fetchAPI(`admin/roles/${roleId}`),
    onSuccess: (_, deletedRole) => {
      queryClient.setQueryData(roleQueryOptions.queryKey, (oldData: Role[]) => {
        if (oldData) {
          return oldData.filter((role) => role.id !== deletedRole.roleId)
        }
        return []
      })
      onSuccess?.()
    },
  })

  return {
    deleteRole: mutate,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}
