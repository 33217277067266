import dayjs from 'dayjs'
import lodashUnescape from 'lodash/unescape'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BodyM, BodyS } from '@/components/common/Text.tsx'
import AttachmentModal from '@/components/templates/ChatArea/AttachmentModal.tsx'
import { useAttachment } from '@/hooks/useAttachment.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import {
  ChatMessageAddedEvent,
  ConversationAssignedEvent,
  ConversationClosedEvent,
  ConversationEvent,
  ConversationMovedEvent,
  ConversationReasonForEntryChangedEvent,
  ConversationUnassignedEvent,
  InternalConversationEvent,
} from '~common/serverToClientParser.ts'

const EventText = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.black62};
  background: ${({ theme }) => theme.colors.common.white};
  padding: 16px;
`

interface ChatEventContainerProps {
  align: 'left' | 'right' | 'center'
  isFirst?: boolean
}

const getFlexPosition = (align: 'left' | 'right' | 'center') => {
  if (align === 'left') {
    return 'flex-start'
  }
  if (align === 'right') {
    return 'flex-end'
  }
  return 'center'
}

const ChatEventContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  :after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    height: 1px;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.common.border};
    width: 100%;
  }
`

const MessageEventContainer = styled.div<ChatEventContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) =>
    props.isFirst === true ? props.theme.spacing * 2 : props.theme.spacing}px;
  align-items: ${(props) => getFlexPosition(props.align)};
`

interface IsFirst {
  isFirst: boolean
}

const MessageContainer = styled.div<IsFirst>`
  padding: 12px 16px;
  max-width: 80%;
  white-space: pre-wrap;
  border-radius: ${(props) => `${props.isFirst ? '0' : '8'}px 8px 8px 8px;`};
`

const MessageSender = styled(BodyS)`
  margin-bottom: 4px;
`

const PatientMessageContainer = styled(MessageContainer)`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background: ${({ theme }) => theme.colors.common.white};
  color: ${({ theme }) => theme.colors.common.black};
`

const ClinicianMessageContainer = styled(MessageContainer)`
  border: 1px solid ${({ theme }) => theme.colors.common.background};
  background: ${({ theme }) => theme.colors.common.background};
  color: ${({ theme }) => theme.colors.common.black};
`

const ImageContainer = styled.div<{ isSender: boolean }>`
  justify-content: ${(props) => (props.isSender ? `flex-end` : `flex-start`)};
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

const ImageWrapper = styled.div`
  padding: 8px 0 0 8px;
`

const ImageSmall = styled.img`
  width: 256px;
  height: 256px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`

const Timestamp = styled.span`
  padding: 4px;
  color: #5a5a5a;
  font-size: 12px;
`

const ImageHidden = styled.div`
  padding: 40px;
  background-color: #eaedf0;
  color: #222222bf;
  border-radius: 8px;
  text-align: center;
`

function Attachment({ onClick, url }: { onClick: () => void; url: string }) {
  const dataUrl = useAttachment(url)
  return <ImageSmall onClick={onClick} src={dataUrl} />
}

export const ChatComponent = ({
  assignedToId,
  createdById,
  event,
  hideAttachments,
  isFirst,
  isLast,
}: {
  assignedToId?: string
  createdById?: string
  event: ConversationEvent | InternalConversationEvent
  hideAttachments?: boolean
  isFirst: boolean
  isLast: boolean
}) => {
  switch (event.type) {
    case 'ChatMessageAdded':
    case 'InternalChatMessageAdded':
      return (
        <ChatMessageAdded
          assignedToId={assignedToId}
          createdById={createdById}
          event={event}
          hideAttachments={hideAttachments}
          isFirst={isFirst}
          isLast={isLast}
        />
      )
    case 'ConversationAssigned':
      return <ConversationAssigned event={event} />
    case 'ConversationUnassigned':
      return <ConversationUnassigned event={event} />
    case 'ConversationMoved':
      return <ConversationMoved event={event} />
    case 'ConversationReasonForEntryChanged':
      return <ConversationReasonForEntryChanged event={event} />
    case 'ConversationClosed':
      return <ConversationClosed event={event} />
    default:
      return null
  }
}

function ChatMessageAdded({
  assignedToId,
  createdById,
  event,
  hideAttachments,
  isFirst,
  isLast,
}: {
  assignedToId?: string
  createdById?: string
  event: ChatMessageAddedEvent | InternalConversationEvent
  hideAttachments?: boolean
  isFirst: boolean
  isLast: boolean
}) {
  const currentUser = useCurrentUser()
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  let isSender = false
  if ('isClinician' in event) {
    isSender = event.isClinician
  } else {
    // if current user is not participant of conversation then sender is user who created conversation
    const isUserSender = [assignedToId, createdById].includes(currentUser.id)
    isSender = isUserSender
      ? currentUser.id === event.userId
      : createdById === event.userId
  }

  const align = isSender ? 'right' : 'left'
  const Wrapper = isSender ? ClinicianMessageContainer : PatientMessageContainer

  const [selectedUrl, setSelectedUrl] = React.useState<string | null>(null)
  const formattedTimestamp = dayjs(event.timestamp).isBefore(dayjs(), 'day')
    ? dayjs(event.timestamp).format('DD/MM/YYYY HH:mm')
    : dayjs(event.timestamp).format('HH:mm')

  return (
    <MessageEventContainer
      align={align}
      data-testid={`messageEvent${event.id}`}
      isFirst={isFirst}
    >
      {event.userName && <MessageSender>{event.userName}</MessageSender>}
      {event.content && (
        <Wrapper isFirst={isFirst}>{lodashUnescape(event.content)}</Wrapper>
      )}
      <ImageContainer isSender={isSender}>
        {event.attachments?.map((a) =>
          hideAttachments ? (
            <ImageHidden key={a.name}>{t('hiddenImage')}</ImageHidden>
          ) : (
            <ImageWrapper key={a.name}>
              <Attachment onClick={() => setSelectedUrl(a.url)} url={a.url} />
            </ImageWrapper>
          ),
        )}
      </ImageContainer>
      {event.timestamp && isLast && <Timestamp>{formattedTimestamp}</Timestamp>}
      {selectedUrl && (
        <AttachmentModal
          onClose={() => setSelectedUrl(null)}
          url={selectedUrl}
        />
      )}
    </MessageEventContainer>
  )
}

function ConversationAssigned({ event }: { event: ConversationAssignedEvent }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <ChatEventContainer data-testid="conversationAssignedEvent">
      <EventText>
        {t('conversationAssignedTo', {
          user: event.assignedToName,
        })}
      </EventText>
    </ChatEventContainer>
  )
}

function ConversationUnassigned({
  event,
}: {
  event: ConversationUnassignedEvent
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <ChatEventContainer>
      <EventText>
        {event.unassignedBySystem
          ? t('conversationUnassignedBySystem')
          : t('conversationUnassignedBy', { user: event.userName })}
      </EventText>
    </ChatEventContainer>
  )
}

function ConversationMoved({ event }: { event: ConversationMovedEvent }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  return (
    <ChatEventContainer>
      <EventText>
        {t('events.conversationMovedToQueue', {
          queue: event.queueTitle,
        })}
      </EventText>
    </ChatEventContainer>
  )
}

function ConversationReasonForEntryChanged({
  event,
}: {
  event: ConversationReasonForEntryChangedEvent
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <ChatEventContainer>
      <EventText>
        {t('conversationReasonForEntryChanged', {
          new: event.newReasonForEntryTitle,
          old: event.oldReasonForEntryTitle,
          user: event.userName,
        })}
      </EventText>
    </ChatEventContainer>
  )
}

function ConversationClosed({ event }: { event: ConversationClosedEvent }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <ChatEventContainer>
      <EventText>
        {t('conversationClosedBy', { user: event.userName })}
      </EventText>
    </ChatEventContainer>
  )
}
