import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import Booking from '@/assets/svgs/booking.svg'
import Clock from '@/assets/svgs/clock.svg'
import { Stack } from '@/components/common/Stack'
import { BodyM, Header1, Header3 } from '@/components/common/Text'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled'
import i18n from '@/i18n'

const Container = styled(Stack)`
  width: 100%;
  position: absolute;
  top: ${NAVBAR_HEIGHT}px;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.common.black95};
  color: white;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 22px;
  height: 22px;
  img {
    color: white;
  }
`

const Link = styled.a`
  color: #8fbaff;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const QRCodeWrapper = styled.div`
  height: 'auto';
  margin: '0 auto';
  padding: 16px;
  background-color: white;
`

const InformationContainer = styled(Stack)`
  width: 300px;
`

const Description = styled(BodyM)`
  margin-top: 20px;
`

const baseVideoCallUrl = import.meta.env.VIDEO_CALL_APP_URL

export function PreCall() {
  const [params] = useSearchParams()
  const { t } = useTranslation('translation', {
    keyPrefix: 'preCall',
  })

  const appointmentCode = params.get('code')
  const patientName = params.get('patientName')
  const dateTime = params.get('date')

  const date = dayjs(dateTime).format('DD/MM/YYYY')
  const time = dayjs(dateTime).format('HH:mm')
  const dayName = getDayName(dateTime as string, i18n.language)

  const videoAppUrl = `${baseVideoCallUrl}?${createSearchParams({
    date: `${dayName}, ${date}`,
    name: patientName as string,
    time: time,
  }).toString()}`

  return (
    <Container direction="row" spacing={48}>
      <QRCodeWrapper>
        <QRCode
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={videoAppUrl}
        />
      </QRCodeWrapper>
      <InformationContainer direction="column" spacing={16}>
        <Header1>{patientName}</Header1>
        <Stack direction="row" spacing={12}>
          <Icon alt="" src={Booking} />
          {dayName}, {date}
        </Stack>
        <Stack direction="row" spacing={12}>
          <Icon alt="" src={Clock} />
          {time}
        </Stack>
        <Description color="white">{t('description')}</Description>
        <Link href={videoAppUrl} target="_blank">
          {baseVideoCallUrl}
        </Link>
        <Header3>{appointmentCode}</Header3>
      </InformationContainer>
    </Container>
  )
}

function getDayName(dateTime: string, locale: string) {
  return new Date(dateTime).toLocaleDateString(locale, { weekday: 'long' })
}
