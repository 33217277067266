import styled from 'styled-components'

import { Header2 } from '@/components/common/Text.tsx'
import { HeaderBar as HB } from '@/screens/admin/UserManagement/UserManagement.styled.ts'
export const Section = styled.div`
  padding: 16px 0;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
`

export const Title = styled(Header2)`
  margin-bottom: 16px;
`

export const HeaderBar = styled(HB)`
  justify-content: space-between;
  gap: 40px;
`
