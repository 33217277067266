import 'dayjs/locale/nl'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import InternalChatLine from '@/assets/svgs/internal-chat-line.svg'
import { BodyM, BodyS, BodySmall } from '@/components/common/Text.tsx'
import { useContactReasons } from '@/hooks/data/useContactReasonts.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { Conversation } from '@/state/stateType.ts'
import { getAmountOfLatestCustomerMessages } from '@/state/stateUtil.ts'

interface ConversationContainerProps {
  readonly $isActive: boolean
}

export interface ConversationSelectorProp {
  assignedToClinician: boolean
  conversation: Conversation
  isActive: boolean
  onSelect: () => void
}

const containerStyle = css`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px 8px;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: end;
`

const ConversationContainer = styled.li<ConversationContainerProps>`
  ${containerStyle};
  cursor: pointer;

  ${({ $isActive, theme }) =>
    $isActive
      ? `
        background-color: ${theme.colors.common.black10}`
      : `
      &:hover {
        background-color: ${theme.colors.common.black05};
      }
      `};
`

const InternalConversationContainer = styled.div`
  ${containerStyle};
  margin-top: 5px;
  padding-top: 0;
  padding-bottom: 0;
  grid-row-end: span 2;
  border-left: 2px solid ${({ theme }) => theme.colors.common.black20};
`
const ConversationInformationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-row: 2;
  grid-column: 2 / 6;
  margin-left: auto;
`

const Name = styled(BodyM)`
  grid-column: 1 / 5;
  grid-row: 1;
  color: ${({ theme }) => theme.colors.common.black};
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Caption = styled(BodyS)`
  grid-row: 2;
`
const MessageInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  :empty {
    display: none;
  }
`
const MessageReceivedContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary.main};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 100%;

  p {
    font-size: 12px;
  }
`
const TagsContainer = styled.div`
  grid-row: 1;
  grid-column: 5;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-start;
`

const HighPriorityTag = styled(BodyS)`
  padding: 3px 8px;
  font-weight: 500;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.common.alert};
  color: ${(props) => props.theme.colors.common.white};
`

function getMessageTime(c: Conversation) {
  // Check if any message from clinician.
  const hasMessageFromClinician = c.events.some(
    (e) => e.type === 'ChatMessageAdded' && e.isClinician,
  )

  // Get all patient messages
  const messagesFromPatient = c.events.filter(
    (e) => e.type === 'ChatMessageAdded' && !e.isClinician,
  )

  // If there is a response to the patient, return latest message time
  // If not return the first message timestamp
  const responseTimestamp = hasMessageFromClinician
    ? messagesFromPatient[messagesFromPatient.length - 1]?.timestamp
    : messagesFromPatient[0]?.timestamp

  return responseTimestamp ? new Date(responseTimestamp) : undefined
}

const getInternalConversationUserName = (
  currentUserId: string,
  internalConversation: NonNullable<Conversation['internalConversation']>,
) => {
  return currentUserId === internalConversation.createdById
    ? internalConversation.assignedToName
    : internalConversation.createdByName
}

export function ConversationSelector({
  assignedToClinician,
  conversation,
  isActive,
  onSelect,
}: ConversationSelectorProp) {
  const { t } = useTranslation('translation', { keyPrefix: 'chat' })
  const currentUser = useCurrentUser()
  const { contactReasons } = useContactReasons()

  const reasonTitle = contactReasons?.find(
    (contactReason) => contactReason.id === conversation.reasonForEntryId,
  )?.title

  const messageTime = getMessageTime(conversation)
  const durationSinceMessage = messageTime
    ? dayjs(messageTime).fromNow()
    : undefined

  const newCustomerMessages = getAmountOfLatestCustomerMessages(conversation)

  return (
    <ConversationContainer
      $isActive={isActive}
      data-testid={`conversation${conversation.id}`}
      onClick={onSelect}
      role="button"
      tabIndex={0}
    >
      <Name>{conversation.patient.name}</Name>
      <TagsContainer>
        {conversation.internalConversation && (
          <img
            alt="internal-chat-icon"
            height={24}
            src={InternalChatLine}
            width={24}
          />
        )}
        {conversation.priority === 'high' && (
          <HighPriorityTag>{t('reasons.Urgent')}</HighPriorityTag>
        )}
      </TagsContainer>
      <Caption>{reasonTitle || conversation.reasonForEntryTitle}</Caption>
      <ConversationInformationContainer>
        <MessageInformation>
          {assignedToClinician ? (
            newCustomerMessages > 0 && (
              <MessageReceivedContainer>
                <BodySmall>{newCustomerMessages}</BodySmall>
              </MessageReceivedContainer>
            )
          ) : (
            <Caption>{durationSinceMessage}</Caption>
          )}
        </MessageInformation>
      </ConversationInformationContainer>
      {conversation.internalConversation && isActive && (
        <InternalConversationContainer>
          <Name>Internal chat</Name>
          <Caption>
            {getInternalConversationUserName(
              currentUser.id,
              conversation.internalConversation,
            )}
          </Caption>
        </InternalConversationContainer>
      )}
    </ConversationContainer>
  )
}
