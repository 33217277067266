export const patientsPath = '/patients'
export const chatPath = '/chat'

export type SelectProgramPathParams = {
  programId?: string
}

export const selectPatientPath = ({ patientId }: SelectPatientPathParams) =>
  `/patients/${btoa(patientId)}`

export const selectProgramPath = ({
  patientId,
  programId,
}: SelectProgramPathParams & SelectPatientPathParams) =>
  `${selectPatientPath({ patientId })}/hand-therapy/select-program${programId ? `/${programId}` : ''}`

export type SelectPatientPathParams = {
  patientId: string
}
export type SelectExercisesPathParams = {
  programId?: string
}

export const selectExercisesPath = ({
  patientId,
  programId,
}: SelectPatientPathParams & SelectExercisesPathParams) =>
  `${selectPatientPath({ patientId })}/hand-therapy/program${programId ? `/${programId}` : ''}`

export const selectPDFLookupPath = ({ patientId }: SelectPatientPathParams) =>
  `${selectPatientPath({ patientId })}/pdf-lookup`
