import { Tab } from '@headlessui/react'
import styled from 'styled-components'

export const StyledTab = styled(Tab)`
  padding: 6px 12px;
  border: 1px solid #e8e8e8;

  &[data-headlessui-state='selected'] {
    background-color: ${({ theme }) => theme.colors.common.black75};
    color: white;
    border-color: ${({ theme }) => theme.colors.common.black75};
  }

  & + & {
    border-left: none;
  }
`
