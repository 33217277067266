import { Popover as HPopover } from '@headlessui/react'
import styled from 'styled-components'

import { StyledButton } from '@/components/common/Buttons.tsx'
import { Stack } from '@/components/common/Stack.tsx'

import { NAVBAR_HEIGHT } from './NavigationBar.styled.ts'

export const Dialog = styled.div`
  position: absolute;
  display: none;
  bottom: 0;
  width: 200px;
  height: 120px;
  left: 88px;
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  color: ${({ theme }) => theme.colors.common.accentLight};
`

export const Avatar = styled.img`
  justify-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;

  &:hover ${Dialog} {
    display: block;
  }
`

export const Panel = styled(HPopover.Panel)`
  position: fixed;
  right: 20px;
  top: ${NAVBAR_HEIGHT + 10}px;
  min-width: 12rem;
  min-height: 12rem;
  z-index: 9999;
`

export const PanelContent = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.common.darkBackground};
  border: 1px solid ${(props) => props.theme.colors.common.border};
  color: ${(props) => props.theme.colors.common.white};
`

export const PopoverButton = styled(HPopover.Button)`
  display: flex;
  align-items: center;
`

export const IconButton = styled(StyledButton)`
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 0.2s ease-in-out;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-transform: none;
  padding: 8px 12px;

  &:hover {
    background-color: ${(props) => props.theme.colors.common.black20};
  }
`

export const LanguageSelection = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;

  button {
    padding: 12px 24px;
    text-transform: uppercase;
    flex: 1;
    text-align: center;
  }
`

export const LangButton = styled.button<{ selected: boolean }>`
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  color: ${({ theme }) => theme.colors.common.white};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.common.black10 : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black05};
  }
`

export const UserData = styled(Stack)`
  padding: 16px 20px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
`

export const LogoutStack = styled(Stack)``
