import { Tab } from '@headlessui/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BodyM } from '@/components/common/Text.tsx'

import { AppointmentTabContent } from './Appointments.tsx'
import { ChatsTabContent } from './Chats'
import { QuestionnairesTabContent } from './Questionnaires.tsx'

const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 1px;
  flex-grow: 1;
  padding: 16px 0;
`

const TabList = styled(Tab.List)``

const TabButton = styled(Tab)`
  padding: 20px 0;
  width: 140px;
  text-align: center;
  border-bottom: 3px solid ${({ theme }) => theme.colors.common.black10};

  &[data-headlessui-state='selected'] {
    color: ${({ theme }) => theme.colors.common.black};
    border-bottom-color: ${({ theme }) => theme.colors.common.black};
    background-color: ${({ theme }) => theme.colors.common.black10};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black05};
  }
`

export const InformationTabs = ({ pulseIds }: { pulseIds?: string }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.tabs',
  })

  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <Tab.Group>
      <TabList>
        <BodyM as={TabButton} fontWeight="bold">
          {t('carePath')}
        </BodyM>
        <BodyM as={TabButton} fontWeight="bold">
          {t('questionnaires')}
        </BodyM>
        <BodyM as={TabButton} fontWeight="bold">
          {t('chats')}
        </BodyM>
      </TabList>
      <Wrapper ref={ref} id="information-tab-wrapper">
        <Tab.Panels>
          <Tab.Panel>
            <AppointmentTabContent pulseIds={pulseIds} scrollableRef={ref} />
          </Tab.Panel>
          <Tab.Panel>
            <QuestionnairesTabContent pulseIds={pulseIds} />
          </Tab.Panel>
          <Tab.Panel>
            <ChatsTabContent pulseIds={pulseIds} />
          </Tab.Panel>
        </Tab.Panels>
      </Wrapper>
    </Tab.Group>
  )
}
