import { Tab } from '@headlessui/react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Chat from '@/assets/svgs/chat-line.svg'
import Search from '@/assets/svgs/search-line.svg'
import { AdminButton } from '@/components/admin/AdminButton.tsx'
import { chatPath, patientsPath } from '@/router/paths.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { Permissions } from '~common/auth/permissions.ts'

import * as S from './NavigationBar.styled.ts'
import { UserInfo } from './UserInfo'

export const NavigationBar = () => {
  const user = useCurrentUser()
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'navbar' })

  const tabs = useMemo(
    () => [
      {
        auth: Permissions.Chat,
        icon: Chat,
        label: t('chat'),
        link: chatPath,
      },
      {
        auth: Permissions.PatientHub,
        icon: Search,
        label: t('patients'),
        link: patientsPath,
      },
    ],
    [t],
  )

  const handleChange = useCallback(
    (index: number) => {
      navigate(tabs[index].link)
    },
    [navigate],
  )

  return (
    <S.Navbar>
      <S.Logo src="/equipe.svg"></S.Logo>
      <S.TabGroup onChange={handleChange} manual vertical>
        <S.TabList>
          {tabs
            .filter((tab) => user.permissions.includes(tab.auth))
            .map((tabItem) => (
              <Tab key={tabItem.label} as={S.TabItemLink} to={tabItem.link}>
                <S.Icon src={tabItem.icon}></S.Icon>
                <S.Label>{tabItem.label}</S.Label>
              </Tab>
            ))}
        </S.TabList>
      </S.TabGroup>
      <AdminButton />
      <UserInfo />
    </S.Navbar>
  )
}
