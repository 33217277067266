import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { ModalBackground, StyledModal } from '@/components/common/Modal.tsx'
import { BodyS, Header1 } from '@/components/common/Text.tsx'
import {
  ErrorMessage,
  StyledActionButtons,
} from '@/screens/admin/UserManagement/UserManagement.styled.ts'

interface DeleteModalProps {
  caption?: string,
  closeModal: () => void,
  errorMessage?: string,
  isError?: boolean,
  onDelete: () => void,
  title: string
}

export const DeleteModal = ({
  caption,
  closeModal,
  errorMessage,
  isError,
  onDelete,
  title,
}: DeleteModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.actions',
  })
  return (
    <ModalBackground closeModal={closeModal}>
      <StyledModal>
        <Header1>{title}</Header1>
        {caption && <BodyS>{caption}</BodyS>}
        <StyledActionButtons>
          <StyledActionButton onClick={closeModal} variant="Secondary">
            {t('cancel')}
          </StyledActionButton>
          <StyledActionButton onClick={onDelete} variant="Primary">
            {t('delete')}
          </StyledActionButton>
        </StyledActionButtons>
        {isError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </StyledModal>
    </ModalBackground>
  )
}
