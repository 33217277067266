import React, { Reducer, useCallback, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Checkbox } from '@/components/common/Checkbox.tsx'
import { Input, Label } from '@/components/common/Input.tsx'
import { Listbox, MultiSelectListbox } from '@/components/common/Listbox.tsx'
import { ModalBackground } from '@/components/common/Modal.tsx'
import { TagInput } from '@/components/common/TagInput.tsx'
import { BodyS, Header3 } from '@/components/common/Text.tsx'
import {
  ErrorMessage,
  Modal,
  StyledActionButtons,
} from '@/screens/admin/UserManagement/UserManagement.styled.ts'
import { Permission } from '@/types/permission.ts'
import { Queue } from '@/types/queue.ts'
import { ReasonWithOrganizations } from '@/types/reason.ts'
import { User } from '@/types/user.ts'
import { Role } from '~common/auth/role'

type DeepKeys<T> = T extends object
  ? {
      [K in keyof T]:
        | `${K & string}`
        | `${K & string}.${DeepKeys<T[K]> & string}`
    }[keyof T]
  : ''

export type FormField<T> = {
  id: DeepKeys<T>
  label: string
} & (
  | { type: 'text' }
  | { displayValue?: string; type: 'multiselect' | 'select'; value?: string }
  | { type: 'tagInput' }
  | { type: 'checkbox' }
)

type EditModalProps<T> = {
  caption?: string,
  closeModal: () => void,
  data?: T,
  errorMessage?: string,
  formFields: FormField<T>[],
  isError?: boolean,
  onSave: (data: T) => void,
  selectOptions?: Partial<Record<DeepKeys<T>, Array<any> | undefined>>,
  title: string
}
export const EditModal = <
  T extends User | Role | Permission | Queue | ReasonWithOrganizations,
>({
  caption,
  closeModal,
  data,
  errorMessage,
  formFields,
  isError,
  onSave,
  selectOptions,
  title,
}: EditModalProps<T>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.actions',
  })
  const initialState = data ? data : ({} as T)

  const [updatedData, dispatch] = useReducer<
    Reducer<T, { payload: string | Array<string | Permission>; type: string }>
  >((state, action) => {
    // update state with nested keys
    const keys = action.type.split('.') as (keyof T)[]
    if (keys.length === 1) {
      return { ...state, [keys[0]]: action.payload }
    }
    let updatedState: any = { ...state }
    keys.slice(0, -1).forEach((key) => {
      updatedState = { ...updatedState[key] }
    })
    updatedState[keys[keys.length - 1]] = action.payload
    return { ...state, [keys[0]]: updatedState }
  }, initialState)

  const handleInputChange = useCallback((id: string, value: any) => {
    dispatch({ payload: value, type: id })
  }, [])

  const handleSave = useCallback(() => {
    onSave(updatedData)
  }, [onSave, updatedData])

  return (
    <ModalBackground closeModal={closeModal}>
      <Modal>
        <Header3>{title}</Header3>
        {caption && <BodyS>{caption}</BodyS>}
        {formFields.map((field) => {
          const fieldIds = field.id.split('.')
          const prevFieldValue = fieldIds.reduce(
            (acc: any, id) => acc?.[id],
            updatedData,
          )

          if (field.type === 'multiselect' || field.type === 'select') {
            const fieldSelectOptions = selectOptions?.[field.id]
            if (!fieldSelectOptions) {
              console.error('Select options not provided for field', field.id)
              return <React.Fragment key={field.id} />
            }

            const parsedOptions = fieldSelectOptions.map(
              (option) =>
                ({
                  title:
                    (field.displayValue && option[field.displayValue]) ||
                    option,
                  value: (field.value && option[field.value]) || option,
                }) || [],
            )

            if (field.type === 'multiselect') {
              const parsedValue = prevFieldValue?.map((value: any) =>
                typeof value === 'string' ? value : value[field.value!],
              )

              return (
                <Label key={field.id}>
                  {field.label}
                  <MultiSelectListbox
                    key={field.id}
                    onChange={(items) => {
                      if (items) {
                        handleInputChange(
                          field.id,
                          field.value
                            ? fieldSelectOptions.filter((option) =>
                                items.includes(option[field.value!]),
                              )
                            : items,
                        )
                      }
                    }}
                    options={parsedOptions}
                    value={parsedValue}
                  />
                </Label>
              )
            }

            if (field.type === 'select') {
              const parsedValue = prevFieldValue && {
                title:
                  (field.displayValue && prevFieldValue[field.displayValue]) ||
                  prevFieldValue,
                value:
                  (field.value && prevFieldValue[field.value]) ||
                  prevFieldValue,
              }

              return (
                <Label key={field.id}>
                  {field.label}
                  <Listbox
                    key={field.id}
                    onChange={(item) => {
                      if (item) {
                        handleInputChange(
                          field.id,
                          field.value
                            ? fieldSelectOptions.find(
                                (option) => option[field.value!] === item.value,
                              )
                            : item.value,
                        )
                      }
                    }}
                    options={parsedOptions}
                    value={parsedValue}
                  />
                </Label>
              )
            }
          }

          if (field.type === 'tagInput') {
            return (
              <Label key={field.id}>
                {field.label}
                <TagInput
                  handleInputChange={handleInputChange}
                  id={field.id}
                  prevTags={prevFieldValue}
                />
              </Label>
            )
          }

          if (field.type === 'checkbox') {
            return (
              <Label key={field.id}>
                <Checkbox
                  checked={prevFieldValue}
                  label={field.label}
                  onChange={(e) =>
                    handleInputChange(field.id, e.target.checked)
                  }
                />
              </Label>
            )
          }

          return (
            <Label key={field.id}>
              {field.label}
              <Input
                key={field.id}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                type="text"
                value={prevFieldValue ?? ''}
              />
            </Label>
          )
        })}
        <StyledActionButtons>
          <StyledActionButton onClick={closeModal} variant="Secondary">
            {t('cancel')}
          </StyledActionButton>
          <StyledActionButton onClick={handleSave} variant="Primary">
            {t('save')}
          </StyledActionButton>
        </StyledActionButtons>
        {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Modal>
    </ModalBackground>
  )
}
