import { useEffect } from 'react'
import styled from 'styled-components'

import { ComposeMessage } from '@/components/templates/ChatArea/ComposeMessage.tsx'
import { InternalConversation } from '@/components/templates/ChatArea/InternalConversation/InternalConversation.tsx'
import { useActiveConversation } from '@/state/hooks/useActiveConversation.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'

import ChatContent from './ChatContent.tsx'
import { TriageAccordion } from './TriageAccordion.tsx'

const ChatContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  grid-row: 2;
  grid-column: 2;
  border: 1px solid ${(props) => props.theme.colors.common.border};
  border-left-width: 0px;
  border-top-width: 0px;
`

const MessageContainer = styled.div`
  height: 1px;
  flex-grow: 1;
  padding: 16px;
  padding-bottom: 48px;
  overflow-y: auto;
`

export function ChatArea() {
  const clinician = useCurrentUser()
  const activeConversation = useActiveConversation()

  const typingUser =
    activeConversation?.typingUser?.id !== clinician.id
      ? activeConversation?.typingUser
      : undefined

  const isAssigned = activeConversation?.assignedToId === clinician.id
  const isInternalChatAssigned =
    activeConversation?.internalConversation?.assignedToId === clinician.id
  const isAttachmentLoading = activeConversation?.isAttachmentLoading

  const handleCopy = (e: ClipboardEvent) => {
    const text_only = document.getSelection()?.toString() as string
    const clipdata = e.clipboardData

    clipdata?.setData('text/plain', text_only)
    e.preventDefault()
  }

  useEffect(() => {
    const chatContainer = document.getElementById('chat')
    chatContainer?.addEventListener('copy', handleCopy)
    return () => {
      chatContainer?.removeEventListener('copy', handleCopy)
    }
  }, [])

  return (
    <>
      <ChatContainer data-testid="chatArea" id="chat">
        <TriageAccordion />
        <MessageContainer>
          <ChatContent
            events={activeConversation?.events}
            typingUserName={typingUser?.name}
            isAttachmentLoading={isAttachmentLoading}
          />
        </MessageContainer>
        {(isAssigned || isInternalChatAssigned) && <InternalConversation />}
      </ChatContainer>
      {isAssigned && <ComposeMessage />}
    </>
  )
}
