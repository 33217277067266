import React from 'react'

import { useAuthentication } from '@/contexts/useAuthenticationContext'
import { loadAttachment } from '@/util/storage.ts'

import { placeholder } from './useAttachment.placeholder'

export function useAttachment(url: string) {
  const { token } = useAuthentication()
  const serviceChatUrl = import.meta.env.API_URL
  const [dataUrl, setDataUrl] = React.useState(placeholder)

  React.useEffect(() => {
    if (token) {
      loadAttachment(serviceChatUrl, token, url).then(setDataUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, token])

  return dataUrl
}
