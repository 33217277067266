import * as React from 'react'
import styled from 'styled-components'

import { BodyM } from '@/components/common/Text.tsx'

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
`

export const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto;
  font-size: 0.875rem;
  caption-side: bottom;
  line-height: 1.25rem;
  border-collapse: collapse;
`

export const TableStyled = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ ...props }, ref) => (
  <TableContainer>
    <StyledTable ref={ref} {...props} />
  </TableContainer>
))
TableStyled.displayName = 'TableStyled'

export const TableHeader = styled.thead`
  & tr {
    border-bottom-width: 1px;
  }
`

export const TableHeadRow = styled.tr``
export const TableHead = styled.th`
  height: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: left;
  vertical-align: top;
  font-weight: 500;
`

export const TableBody = styled.tbody`
  & tr:last-child {
    border-width: 0;
  }
`

export const TableRow = styled.tr`
  border-bottom-width: 1px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  &:nth-child(odd) {
    background-color: rgba(114, 120, 126, 0.15);
  }

  &:hover {
    background-color: rgba(114, 120, 126, 0.3);
  }
`

export const TableCell = styled.td`
  padding: 8px;
`

export const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TableCaption = styled(BodyM)`
  margin-bottom: 20px;
`
