import { Popover, Transition } from '@headlessui/react'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosInformationCircle } from 'react-icons/io'
import styled from 'styled-components'

import { Stack } from '@/components/common/Stack'
import { Header4, Text } from '@/components/common/Text'
import { CarePath } from '@/types/patient'
import { groupCarePlans } from '@/util/care-plans'

const TooltipPanel = styled(Popover.Panel)`
  position: absolute;
  z-index: 10;
`

const TooltipContent = styled.div`
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid #22222233;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: white;
    transform: rotate(45deg);
    border-style: solid;
    border-color: #22222233;
    border-width: 1px 0 0 1px;
    left: 18px;
    top: -9px;
  }
`

const Label = styled(Text)`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.main};
  padding: 16px 0;
`

const InfoIcon = styled(IoIosInformationCircle)`
  margin-right: 8px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.common.black10};
  margin: 16px 0;
`

const SectionTitle = styled.div`
  margin-bottom: 22px;
  color: ${({ theme }) => theme.colors.common.black62};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`

interface CarePlansStackProps {
  paddingBottom: boolean
}
const CarePlansStack = styled(Stack)<CarePlansStackProps>`
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '20px' : '0')};
`

const Tooltip = ({
  children,
  hidden,
  label,
}: {
  children: any
  hidden: boolean
  label: ReactElement
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover style={{ position: 'relative' }}>
      <Popover.Button
        onMouseEnter={() => !hidden && setIsOpen(true)}
        onMouseLeave={() => !hidden && setIsOpen(false)}
      >
        {label}
      </Popover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        show={isOpen}
      >
        <TooltipPanel static>
          <TooltipContent>{children}</TooltipContent>
        </TooltipPanel>
      </Transition>
    </Popover>
  )
}

const CarePlans = ({
  carePlans,
  paddingBottom,
}: {
  carePlans: CarePath[]
  paddingBottom?: boolean
}) => {
  return (
    <CarePlansStack
      direction="column"
      paddingBottom={!!paddingBottom}
      spacing={24}
    >
      {carePlans.map((path) => (
        <Stack key={path.id} direction="column" spacing={4}>
          <Header4>{path?.diagnosis?.name}</Header4>
          <Text>
            {path?.treatment?.name}
            {path?.treatment?.date &&
              ` - ${dayjs(path.treatment?.date).format('DD/MM/YYYY')}`}
          </Text>
        </Stack>
      ))}
    </CarePlansStack>
  )
}

export const CarePlansCondensed = ({
  carePlans,
  fixedActiveCarePlans,
}: {
  carePlans: CarePath[]
  fixedActiveCarePlans: boolean
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.carePath',
  })
  const { onGoingCarePlans, pastCarePlans } = groupCarePlans(carePlans)

  const fixedCarePlans = fixedActiveCarePlans
    ? onGoingCarePlans.slice(0, 2)
    : []

  return (
    <>
      <Tooltip
        hidden={!carePlans.length}
        label={
          <Label>
            <InfoIcon />
            {getLabel(t, onGoingCarePlans.length, pastCarePlans.length)}
          </Label>
        }
      >
        {!!onGoingCarePlans.length && (
          <>
            <SectionTitle>{t('currentCarePaths')}</SectionTitle>
            <CarePlans carePlans={onGoingCarePlans} />
          </>
        )}
        {!!onGoingCarePlans.length && !!pastCarePlans.length && <Divider />}
        {!!pastCarePlans.length && (
          <>
            <SectionTitle>{t('closedCarePaths')}</SectionTitle>
            <CarePlans carePlans={pastCarePlans} />
          </>
        )}
      </Tooltip>
      {!!fixedCarePlans.length && (
        <CarePlans carePlans={fixedCarePlans} paddingBottom={true} />
      )}
    </>
  )
}

function getLabel(
  t: TFunction<'translation', 'patientSidebar.carePath'>,
  ongoing: number,
  closed: number,
): string {
  let label = ''
  if (!ongoing) {
    label += t('noActiveCarePaths')
  } else if (ongoing === 1) {
    label += t('numActiveCarePathsSingular')
  } else {
    label += t('numActiveCarePathsPlural', { num: ongoing })
  }
  label += ', '
  if (!closed) {
    label += t('noClosedCarePaths')
  } else if (closed === 1) {
    label += t('numClosedCarePathsSingular')
  } else {
    label += t('numClosedCarePathsPlural', { num: closed })
  }
  return label
}
