import styled from 'styled-components'

import { StyledModal } from '@/components/common/Modal.tsx'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
`

export const TableContainer = styled.div`
  flex: 1;
`
export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 20px;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const StyledActionButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

export const Modal = styled(StyledModal)`
  width: 60%;
  max-width: 600px;
  max-height: 100%;
  
  & input,
  & label {
    width: 100%;
  }
`

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-size: 12px;
`
