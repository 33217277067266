import { Column, Table } from '@tanstack/react-table'
import { useMemo } from "react";

import { DebouncedInput } from '@/components/common/Input.tsx'
import { MultiSelectListbox } from '@/components/common/Listbox.tsx'
import { Switch } from '@/components/common/Switch.tsx'

export const FilterInput = ({
  column,
}: {
  column: Column<any, unknown>
  table: Table<any>
}) => {
  const sortedUniquesValues = useMemo(() => new Set([...column.getFacetedUniqueValues().keys()].flat()), [column.getFacetedUniqueValues()])
  if (column.columnDef.meta?.isBooleanFilter) {
    return (
      <Switch
        checked={column.getFilterValue() as boolean}
        onChange={(value: boolean) => {
          value
            ? column.setFilterValue(value)
            : column.setFilterValue(undefined)
        }}
      />
    )
  }
  if (column.columnDef.meta?.isSelectFilter) {
    return (
      <>
        <MultiSelectListbox
          onChange={(value) => column.setFilterValue(value)}
          options={[...sortedUniquesValues].map((option) => ({
            title: option,
            value: option,
          }))}
          value={(column.getFilterValue() as string[]) ?? []}
        ></MultiSelectListbox>
      </>
    )
  }
  return (
    <DebouncedInput
      onChange={(value) => {
        column.setFilterValue(value)
      }}
      placeholder={`Search... `}
      type="text"
      value={(column.getFilterValue() as string) ?? ''}
    />
  )
}
