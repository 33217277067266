import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ExerciseLine from '@/assets/svgs/exercise-line.svg'
import PdfOutline from '@/assets/svgs/pdf-outline.svg'
import { NavigationCard } from '@/components/templates/NavigationCard/NavigationCard.tsx'
import { InformationTabs } from '@/components/templates/PatientSidebar/InformationTabs.tsx'
import { PatientInformation } from '@/components/templates/PatientSidebar/PatientInformation.tsx'
import { usePatientProgram } from '@/hooks/data/usePatientProgram.ts'
import {
  selectExercisesPath,
  selectPDFLookupPath,
  selectProgramPath,
} from '@/router/paths.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { usePatientPulseIdFromParams } from '@/state/hooks/usePatientPulseId.ts'
import { Permissions } from '~common/auth/permissions.ts'

import * as S from './PatientScreen.styled.ts'

export const PatientScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientScreen',
  })
  const user = useCurrentUser()
  const { patientId } = usePatientPulseIdFromParams() as {
    patientId: string
  }

  const navigate = useNavigate()
  const { isFetched, program } = usePatientProgram(patientId)

  const navigateToExercises = () => {
    if (patientId && isFetched) {
      if (program) {
        navigate(
          selectExercisesPath({
            patientId,
            programId: program.id,
          }),
          { state: { programContentfulId: program.contentfulId } },
        )
        return
      } else {
        navigate(selectProgramPath({ patientId }))
      }
    }
  }
  return (
    <S.Main>
      <S.GridContainer>
        <S.HeaderSection>
          <PatientInformation orientation="horizontal" pulseIds={patientId} />
        </S.HeaderSection>
        <S.PatientNavigation>
          {user.permissions.includes(Permissions.HandTherapy) && (
            <NavigationCard
              description={t('navigation.exercises.description')}
              iconSrc={ExerciseLine}
              onClick={navigateToExercises}
              title={t('navigation.exercises.title')}
            />
          )}
          {user.permissions.includes(Permissions.PDFLookup) && (
            <NavigationCard
              description={t('navigation.pdfLookup.description')}
              disabled={!patientId}
              iconSrc={PdfOutline}
              onClick={
                patientId
                  ? () => navigate(selectPDFLookupPath({ patientId }))
                  : undefined
              }
              title={t('navigation.pdfLookup.title')}
            />
          )}
        </S.PatientNavigation>
        <S.SidebarContainer>
          <InformationTabs pulseIds={patientId} />
        </S.SidebarContainer>
      </S.GridContainer>
    </S.Main>
  )
}
